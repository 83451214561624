import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/_index.scss";
import '../src/assets/icon-style.css'
import 'halleyx-ui-framework/dist/es/index.css'
import halleyxKit from 'halleyx-ui-framework'
/* eslint-disable */
createApp(App).use(store).use(halleyxKit).use(router).mount("#app");
