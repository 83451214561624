import { createStore } from 'vuex';

export default createStore({
  state: {
    language: 'en',
    languageOptions: [{ name: 'EN'}, { name: 'FR' }],
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
  },
  getters: {},
  actions: {},
  modules: {},
});
