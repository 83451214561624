<template>
  <router-view @route-change="updateMeta"/>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  watch: {
    $route(to) {
      this.updateMeta(to);
    }
  },
  mounted() {
    this.updateMeta(this.$route);
  },
  methods: {
    updateMeta() {
      // const noindexMeta = document.querySelector('meta[name="robots"]');
      // if (noindexMeta) {
      //   noindexMeta.remove();
      // }
    }
  },
};
</script>
<style lang="scss">
#app {
  [class^="icon-"], [class*=" icon-"] {
        font-family: 'magicoon-icons' !important;
    }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #191a15
}
</style>
